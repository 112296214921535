import React from 'react';
import ImageTag from '../ImageTag/Index';
import './index.css';
import BreadCrumbs from '../BreadCrumb/Index'; // Import BreadCrumbs

const Index = ({ Data }) => {
    return (
        <section className='col-12 float-start flex-center overflow-hidden flex-wrap'>
            <div className='col-12 float-start InternalBanner position-relative'>
                <ImageTag ImagePath={Data.imagePath} ImageAlt={Data.imageAlt} />
                {Data.heading && (
                    <h1 className='heading position-absolute text-white greenBorder d-block px-lg-4 px-3' dangerouslySetInnerHTML={{ __html: Data.heading }} />
                )}
            </div>
            {/* Use the simplified BreadCrumbs component */}
            <BreadCrumbs _parentClass={'col-12 float-start'} /> 
        </section>
    );
};

export default Index;
