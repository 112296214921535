import React from 'react'
import Container from '../Container/Index'
import Title from '../Title/Index'

const Moto = ({Data}) => {
  return (
    <Container _parentClass={'ourMoto'} containerClass={'float-end '}>
          <div className="col-12 float-start moto_Bg_Image p-100 blueLayer">
          <Title firstHeading={Data.title} _spanClass={'text-white'} secondHeading={Data.descp} _secondHeading={'text-white'}/>
            <div className='col-lg-10 col-sm-12 col-12 m-auto position-relative'>
            <ul className='motoListitems d-flex justify-content-between text-center text-white mt-sm-3 mb-sm-3'>
            {Data.motolist.map((items, index) => (
          <li key={index} className='position-relative'>
            <strong className='mb-2 float-start col-12 text-center'>{items.title}</strong>
            <p dangerouslySetInnerHTML={{__html : items.description}} />
          </li>
        ))}
      </ul>          
            </div>
        </div>   
    </Container>
  )
}

export default Moto