import React, { useEffect, useState } from "react";
import Container from "./components/Container/Index";
import Title from "./components/Title/Index";
import BreadCrumb from "./components/BreadCrumb/Index";
import MediaNavabar from "./components/Header/MediaNavabar";
import Gallery from "./components/Gallery/Index";
import { API_BASE_URL } from "./Utils/useApi";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const Media = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]); // Initialize as empty array instead of null
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHomeData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/media_gallery.php?cat=${slug}`
        );
        setData(response.data);
        console.log("this is the response", response.data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchHomeData();
  }, [slug]);

  // Only show error state if there's an error
  if (error) return <div>Error: {error}</div>;

  // Calculate unique months and years only if data is available
  const uniqueMonths =
    data.length > 0
      ? [
          ...new Set(
            data.map((item) => {
              const date = new Date(item.datemonth);
              return date
                .toLocaleString("default", { month: "short" })
                .toUpperCase();
            })
          ),
        ]
      : [];

  const uniqueYears =
    data.length > 0
      ? [
          ...new Set(
            data.map((item) => {
              const date = new Date(item.datemonth);
              return date.getFullYear().toString();
            })
          ),
        ]
      : [];

  function formatSlug(slug) {
    return slug
      .toString()
      .toLowerCase()
      .replace(/-/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <>
      <BreadCrumb
        customBreadCrum={true}
        firstName={"media"}
        pageLink={"#"}
        secondName={formatSlug(slug)}
        _parentClass={"customBreadcrum"}
      />
      <Container _parentClass={"p-100"}>
        <div className="col-lg-10 col-11 m-auto overflow-hidden">
          <Title
            secondHeading={
              slug === "events" ? "inauguration" : formatSlug(slug)
            }
            firstHeading={"Media & News"}
          />
          <div className="row">
            <MediaNavabar />
            {loading ? (
              <div className="h-full flex-center">
                <CircularProgress size={24} />
              </div>
            ) : (
              <Gallery
                gallery_data={data}
                uniqueMonths={uniqueMonths}
                uniqueYears={uniqueYears}
                videoGallery={slug === "videos"}
                eventGallery={slug === "events"}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Media;
