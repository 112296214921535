import React, { useEffect, useRef, useState } from "react";
import "../Header/header.css";
import Container from "../Container/Index";
import Logo from "../Header/Logo/Index";
import Navbar from "../Header/Navbar/Index";
import useStickyHeader from "./useStickyHeader";
import { NavLink } from "react-router-dom";
import Mobilenav from "../Header/Navbar/Mobilenav";
import Product01 from "../../images/products01.webp";
import Product02 from "../../images/products02.webp";
import axios from "axios";

const Index = ({ relativeHeader }) => {
  const headerRef = useRef(null);
  useStickyHeader(headerRef);

  const [menuData, setMenuData] = useState([
    { name: "Home", link: "/" },
    { name: "About us", link: "/about-iec-gensets" },
    {
      name: "Power Solutions",
      link: "/power-solutions",
      submenus: [],
    },
    { name: "Manufacturing Plant", link: "/manufacturing-plant" },
    { name: "Certifications", link: "/certifications" },
    { name: "Clients", link: "/clients" },
    { name: "Customer Support", link: "/customer-support" },
    { name: "Careers", link: "/career" },
    { name: "Media", link: "/media/press-release" },
    { name: "Contact Us", link: "/contact-us" },
  ]);
  useEffect(() => {
    axios
      .get(
        "https://triverseadvertising.com/iec_website/admin-portal/api/menu.php"
      )
      .then((response) => {
        const apiMenuData = response.data.map((item) => ({
          icon: item.imagePath
            ? item.imagePath
            : item.title === "Gas Gensets"
            ? Product02
            : item.title === "Diesel Gensets"
            ? Product01
            : null,
          text: item.title,
          link: `/power-solutions/${item.slug}`,
        }));

        // Update the submenu for Power Solutions
        setMenuData((prevMenuData) => {
          return prevMenuData.map((menuItem) => {
            if (menuItem.name === "Power Solutions") {
              return { ...menuItem, submenus: apiMenuData };
            }
            return menuItem;
          });
        });
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });
  }, []);

  const limitedMenuData = menuData.slice(0, 6);

  return (
    <header
      className={`col-12 float-start top-0 overflow-hidden ${relativeHeader}`}
    >
      <Container ref={headerRef} _parentClass="header header-border">
        <div className="col-lg-2 col-7">
          <div
            className="logo"
            data-aos="zoom-in"
            data-aos-offset="100"
            data-aos-duration="500"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <NavLink to="/">
              <Logo />
            </NavLink>
          </div>
        </div>
        <div className="col-lg-10 col-5 d-flex align-items-baseline justify-content-end">
          <div className="row h-100 m-0 desktop-show">
            <Navbar menuData={limitedMenuData} />
          </div>
          <div className="leftMenu">
            <Mobilenav Data={menuData} />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Index;
