import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "./components/Container/Index";
import ImageTag from "./components/ImageTag/Index";
import "./components/Products/Index.css";
import FillShape from "./images/Vectors/FillShape";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import Download from "./images/Vectors/Download";
import { API_BASE_URL } from "./Utils/useApi";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Title from "./components/Title/Index";
import { Link } from "react-router-dom";

const ProductDetails = () => {
  const { cat, slug } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation(); // Get the current URL path

  // List of slugs where we want to render ProductDetails directly
  const detailPageSlugs = ["turnkey-projects", "compact-substations"];

  // Dynamically assign the slug and cat based on the URL or default to specific values
  const effectiveSlug =
    slug ||
    detailPageSlugs.find((slug) => location.pathname.includes(slug)) ||
    "";
  const effectiveCat =
    cat ||
    detailPageSlugs.find((slug) => location.pathname.includes(slug)) ||
    "";
  console.log("effective cat", effectiveCat);
  console.log("effective slug", effectiveSlug);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/product_details.php?cat=${effectiveCat}&slug=${effectiveSlug}`
        );
        setData(response.data);
        console.log("this is the api  data of detail page", response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading)
    return (
      <div>
        <div className="preloader flex-center">
          <CircularProgress size={24} />
        </div>
      </div>
    );
  if (error) return <div>Error: {error}</div>;
  if (!data)
    return <div className="preloader flex-center">No data available</div>;

  const { productDetail, downloads, specification } = data;
  console.log("rhis is hreheightldb", data.productDetail.Highlights);
  const Highlights = [
    {
      id: 1,
      title: "Our Key Offerings",
      info: [
        {
          subtitle: "Design & Engineering",
          descp:
            "Expertly crafted designs tailored to meet specific project needs, ensuring efficiency and reliability.",
        },
        {
          subtitle: "Supply of Equipment",
          descp: "",
          listpoints: [
            "DG Sets (up to 1500 kVA)",
            "Synchronizing/AMF Panels",
            "PCC/MCC Panels",
            "RMU (Ring Main Unit)",
            "Compact Substations",
          ],
        },
        {
          subtitle: "Installation & Testing",
          descp:
            "Complete installation services followed by thorough testing to ensure flawless operation.",
        },
        {
          subtitle: "Commissioning",
          descp:
            "Handing over fully operational systems that meet all performance and safety standards.",
        },
      ],
    },
    {
      id: 2,
      title: "Additional Capabilities",
      info: [
        {
          subtitle: "Cabling & Earthing",
          descp:
            "Installation of high-quality cabling and earthing systems for safe and efficient operation.",
        },
        {
          subtitle: "Exhaust Extensions",
          descp:
            "Custom exhaust solutions, including heat exchanger options for basement installations.",
        },
      ],
    },
    {
      id: 3,
      title: "Why Choose Us?",
      info: [
        {
          descp:
            "Decades of experience in delivering successful projects across industries.",
        },
        {
          descp: "Highly skilled team of engineers and technicians",
        },
        {
          descp: "Commitment to quality, safety, and sustainability.",
        },
      ],
    },
  ];

  return (
    <>
      <main>
        {productDetail.layout == "layout1" ? (
          <Container
            _parentClass={"productInfo creativeRow"}
            containerClass={"float-end"}
          >
            <div className="col-12 float-start creativeHeading">
              <div className={`pagebreadcumb mt-2 `}>
                <ul className="d-flex">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/power-solutions">Power Solutions</Link>
                  </li>
                  {productDetail.product_slug ===
                  "compact-substations" ? null : (
                    <>
                      {productDetail.directLink === "0" ? (
                        <li>
                          <Link to={`/power-solutions/${effectiveCat}`}>
                            {productDetail.cat}
                          </Link>
                        </li>
                      ) : null}
                    </>
                  )}

                  <li>{productDetail.title}</li>
                </ul>
              </div>
            </div>
            <div className="col-12 float-start creativeBannner">
              <div className="row">
                {productDetail.creativeImage ? (
                  <div className="col-lg-6 col-sm-12 col-12 p-0 ">
                    <div className="productImage flex-center">
                      <ImageTag
                        ImagePath={productDetail?.creativeImage}
                        ImageAlt={productDetail.title}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`col-lg-6 col-sm-12 col-12 p-0 ${
                      !productDetail?.productImg ? "flex-center grayColor" : ""
                    }`}
                  >
                    <div className="productImage flex-center">
                      <ImageTag
                        ImagePath={productDetail?.productImg}
                        ImageAlt={productDetail.title}
                      />
                    </div>
                  </div>
                )}
                <div className="col-lg-6 col-sm-12 col-12 p-lg-5 p-3 pb-0">
                  <div className="iecProductinfo col-12 float-start">
                    <p>{productDetail.title}</p>
                    <h1>{productDetail.cat}</h1>
                    <div className="col-lg-10 col-sm-12 col-12 float-start py-3">
                      <ul className="speciFication">
                        {specification.map((spec, index) => (
                          <li key={index} className="col-12 float-start d-flex">
                            <label className="text-uppercase fw-bold col-lg-6 col-12">
                              {spec.title}
                            </label>
                            <span className="col-lg-6 col-12">
                              {spec.value}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {downloads.productPath && (
                      <div className="col-lg-10 col-sm-12 col-12 float-start mt-3">
                        <div className="col-12 float-start flex-wrap gap-2 py-1 downloadBotton">
                          <a
                            className="text-white text-uppercase"
                            href={downloads.productPath}
                            target="_blank"
                          >
                            <Download />
                            Download Brochure
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <Container _parentClass={"productInfo"} containerClass={"float-end"}>
            <div className="col-lg-12 col-sm-12 col-12 p-0 flex-center grayColor">
              <div className="productImage">
                <ImageTag ImagePath={productDetail?.creativeImage || null} />
              </div>
            </div>
            <div className="col-12 float-start">
              <div className={`pagebreadcumb mt-2 `}>
                <ul className="d-flex">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/power-solutions">Power Solutions</Link>
                  </li>
                  {productDetail.product_slug === "turnkey-projects" ? null : (
                    <li>
                      <Link
                        to={`/power-solutions/${productDetail.product_slug}`}
                      >
                        {productDetail.cat}
                      </Link>
                    </li>
                  )}
                  <li>{productDetail.title}</li>
                </ul>
              </div>
            </div>
          </Container>
        )}
        {productDetail.layout == "layout1" ? (
          <Container
            _parentClass={"productOverview"}
            containerClass={"float-end"}
          >
            <div className="col-lg-11 col-12 m-auto">
              <div className="row flex-center">
                <div className="col-lg-5 col-sm-11 col-12 position-relative m-auto">
                  <Title
                    firstHeading={productDetail.overviewTitle}
                    secondHeading={productDetail.overviewHeading}
                    subTitle={productDetail.overviewDesc}
                    _class={"text-lg-start"}
                  />
                  <div className="bottomShape">
                    <FillShape />
                  </div>
                  {productDetail.creativeImage && (
                    <div
                      className="col-12 float-start text-center"
                      data-aos="zoom-in"
                      data-aos-offset="100"
                      data-aos-duration="500"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <ImageTag ImagePath={productDetail.productImg} />
                    </div>
                  )}
                </div>
                <div className="col-lg-7 col-sm-12 col-12 position-relative">
                  {productDetail.featureDesc && (
                    <div className="card px-3 px-lg-5 py-5 col-lg-10 col-12 float-end featureCard">
                      <div className="feature">
                        <div className="title mb-4">
                          <h3 className="FeatureHeading bg-secondary text-white w-auto d-inline">
                            {productDetail.featureHeading}
                          </h3>
                        </div>
                        <ul className="listItems">
                          {productDetail.featureDesc.map((feature, index) => (
                            <li key={index}>
                              {feature.title && (
                                <strong>{feature.title}</strong>
                              )}
                              {feature.heading && <p>{feature.heading}</p>}
                              {Array.isArray(feature.desc) &&
                              feature.desc.some(
                                (content) => content.trim() !== ""
                              ) ? (
                                <ul>
                                  {feature.desc.map(
                                    (content, index) =>
                                      content.trim() !== "" && (
                                        <li key={index}>{content}</li>
                                      )
                                  )}
                                </ul>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <Container containerClass={"float-end"}>
            <div className="col-lg-11 col-11 m-auto">
              <div className="col-12 float-start mb-lg-5 pb-lg-5 mb-3">
                <div className="row">
                  <div className="col-lg-8 col-12 m-auto">
                    <Title
                      firstHeading={productDetail.overviewTitle}
                      secondHeading={productDetail.overviewHeading}
                      subTitle={productDetail.overviewDesc}
                    />
                  </div>
                  <div className="col-lg-12 col-sm-12 col-12 m-auto position-relative creativeGradienBox">
                    <div className="row">
                      {productDetail.Highlights.map((keyOffer) => (
                        <div
                          className="col-lg-4 col-12"
                          key={keyOffer.id}
                          data-aos="fade-up"
                          data-aos-offset="100"
                          data-aos-duration="500"
                          data-aos-once="true"
                          data-aos-easing="ease-in-sine"
                        >
                          <div className="cardStnComponent card gradient w-100 text-white radius-25">
                            <h2 className="productTitle w-100 text-uppercase">
                              {keyOffer.title}
                            </h2>
                            <ul>
                              {keyOffer.info.map((items, index) => (
                                <li key={index}>
                                  <strong>{items.subtitle} </strong>
                                  <p>{items.descp}</p>
                                  {items.listpoints && (
                                    <ul>
                                      {items.listpoints.map((point, i) => (
                                        <li key={i}>{point}</li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 flaot-start">
                <div className="row">
                  <div className="col-lg-5 col-sm-11 col-12 position-relative">
                    <div className="bottomShape">
                      <FillShape />
                    </div>
                    <div
                      className="col-12 float-start"
                      data-aos="zoom-in"
                      data-aos-offset="100"
                      data-aos-duration="500"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <ImageTag ImagePath={productDetail.productImg} />
                    </div>
                  </div>
                  <div className="col-lg-7 col-sm-12 col-12 position-relative">
                    {productDetail.featureDesc && (
                      <div className="card px-3 px-lg-5 py-5 col-lg-10 col-12 float-end featureCard">
                        <div className="feature">
                          <div className="title mb-4">
                            <h3 className="FeatureHeading bg-secondary text-white w-auto d-inline">
                              {productDetail.featureHeading}
                            </h3>
                          </div>
                          <ul className="listItems">
                            {productDetail.featureDesc.map((feature, index) => (
                              <li key={index}>
                                {feature.title && (
                                  <strong>{feature.title}</strong>
                                )}
                                {feature.heading && <p>{feature.heading}</p>}
                                {Array.isArray(feature.desc) &&
                                feature.desc.some(
                                  (content) => content.trim() !== ""
                                ) ? (
                                  <ul>
                                    {feature.desc.map(
                                      (content, index) =>
                                        content.trim() !== "" && (
                                          <li key={index}>{content}</li>
                                        )
                                    )}
                                  </ul>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        )}
        <BusinessEnquiry />
      </main>
    </>
  );
};

export default ProductDetails;
