import React from 'react';
import Swiperslider from '../Swiperslider/Index';
import ImageTag from '../ImageTag/Index';
import '../Heroslider/index.css';
import FillShape from '../../images/Vectors/FillShape';

const Index = ({ Data }) => {
  return (
    <section className='col-12 float-start bg-primary flex-center overflow-hidden heroSlider'>
        <Swiperslider spaceBetween={1} slidesPerView={1} navigation={false} pagination={{ clickable: true }} timeDelay={'6000'} effect={'fade'}>
          {Data.map((slide, index) => (
            <div
              className={`col-12 float-start slideParent`} key={index} >
                    <ImageTag ImagePath={slide.imagePath} ImageAlt={slide.imageAlt} ImageClass={'w-100 desktop-show'}/>
                    <ImageTag ImagePath={slide.mobimgPath} ImageAlt={slide.imageAlt} ImageClass={'w-100 mobile-show'}/>
                    <em className='HomesldierbottomShape'><FillShape /></em>
                    <div className='col-lg-5 col-md-5 col-xs-12 col-12 bannerTextSlide d-none'>
                    <span className='heading text-white greenBorder position-relative d-block px-lg-4 px-3' dangerouslySetInnerHTML={{ __html: slide.heading }} />
                    <em className='HomesldiertopShape'><FillShape /></em>
                  </div>
            </div>
          ))}
        </Swiperslider>
    </section>
  );
}

export default Index;
