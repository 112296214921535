import React, { useState, useEffect } from "react";
import axios from "axios";
import Banner from "./components/Banner/Index";
import data from "./Json/Clients.json";
import Container from "./components/Container/Index";
import FillShape from './images/Vectors/FillShape';
import GallerySlider from "./components/Gallery/GallerySlider";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import Title from './components/Title/Index'
import ImageTag from './components/ImageTag/Index'
import { API_BASE_URL } from "./Utils/useApi";
import { CircularProgress } from "@mui/material";

const Clients = () => {
  const [Data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/clients.php`);
        setData(response.data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading)
    return (
      <div className="preloader flex-center">
      <CircularProgress size={24} />
    </div>
    );
  if (error) return <div>Error: {error}</div>;
  if (!Data) return null;

  const {
    clientData, homeBanners
  } = Data;
  return (
    <main>
        {homeBanners && <Banner Data={homeBanners[0]} />}
        <Container _parentClass="Overview" containerClass={'position-relative float-end'}>
          <div className="col-12 float-start FillshapeCenter">          
              <div className="col-lg-6 col-12 m-auto">
                <Title firstHeading={clientData.heading} secondHeading={clientData.title} subTitle={clientData.descp}/>            
              </div>
          </div>
          <div className='col-12 float-start clientLogo border-top'>
            <div className='row'>
              {clientData.clientLogo.map((logo, index) => (
            <div className='col-lg-2 col-sm-4 col-6 text-center flex-center pt-lg-5 pb-lg-5 pt-4 pb-4 border-bottom border-end' key={index} data-aos="fade-in" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine">
              <ImageTag ImagePath={logo.imagePath} ImageAlt={logo.imageAlt} />
            </div>
          ))}
            </div>
          </div>
        </Container>
        <BusinessEnquiry />
        </main>
  )
}

export default Clients