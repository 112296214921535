import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Outline from "../../Button/Outline/Index";
import Fill from "../../Button/Fill/Index";
import SubMenu from "../../Header/Navbar/SubMenu";
import "../Navbar/Navbar.css";
import { NavLink, useLocation } from "react-router-dom"; // Import useLocation to get the current path

const Index = ({ menuData }) => {
  const location = useLocation(); // Get the current route path

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const isActive = (href) => {
    return location.pathname === href; // Check if the current path matches the href
  };

  return (
    <div className="navbar gap-3 p-0">
      <div className="col-12 float-start d-flex justify-content-end gap-3">
        <Outline
          buttonText="Career"
          _class={"whitetextColor"}
          pageLink={"/career"}
        />
        <Outline
          buttonText="Contact us"
          _class={"whitetextColor"}
          pageLink={"/contact-us"}
        />
        <Fill
          pageLink={"request-quote"}
          buttonText2="Request a Quote"
          buttonText1="Request a Quote"
        />
      </div>
      <div className="col-12 float-start navbarcolumn d-flex justify-content-end align-items-center">
        <div className="menuStrip">
          <ul className="d-flex">
            {menuData.map((menu, index) => (
              <li key={index}>
                <span
                  data-aos="fade-in"
                  data-aos-offset="100"
                  data-aos-duration="500"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  {menu.link ? (
                    <NavLink
                      to={menu.link}
                      className={isActive(menu.link) ? "active" : ""}
                    >
                      {menu.name}
                    </NavLink>
                  ) : (
                    menu.name
                  )}
                </span>
                {menu.submenus && (
                  <SubMenu
                    submenus={menu.submenus}
                    showViewAllLink={menu.showViewAllLink}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;
