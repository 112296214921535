import React from "react";
import "./index.css";
import Container from "../Container/Index";
import Swiperslider from "../Swiperslider/Index";
import ImageTag from "../ImageTag/Index";
import Title from "../Title/Index"

const GallerySlider = ({ Data }) => {
  return (
    <Container
      containerClass={"float-end p-0"}
      _parentClass={"gallerySwiper whiteArrow"}
      sectionID={`gallery`}
    >
      <div className="col-lg-7 col-sm-12 col-12 m-auto position-relative">
        <Title firstHeading={Data.heading} secondHeading={Data.title} subTitle={Data.descp}/>
        </div>
      <div
        className="col-12 float-start gallerySlider"
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-duration="500"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <Swiperslider
          spaceBetween={10}
          slidesPerView={1.5}
          navigation={true}
          timeDelay={8000}
          centeredSlides={true}  
          tabsliderperview={1.5}
        smallsliderperview={1.5}
        mobsliderperview={1.5}        
        >
          {Data.galleryData.map((logo, index) => (
            <div
              className="col-12 text-center flex-center blackLayer position-relative"
              key={index}
            >
              <ImageTag ImagePath={logo.imagePath} ImageAlt={logo.imageAlt} />
            </div>
          ))}
        </Swiperslider>
      </div>
    </Container>
  );
};

export default GallerySlider;
