import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkFormFilled, getFormResponse } from "./Utils/formStatus";
import WebContainer from "./components/WebContainer/Index";

const ThankYouPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkFormFilled()) {
      navigate.push("/"); // Redirect to home if the form is not filled
    }
  }, [navigate]);

  const responseData = getFormResponse();

  return (
    <WebContainer _parentClass="Overview" containerClass={"position-relative float-end"}>
      <div className="d-flex  justify-content-center align-items-center flex-column p-100 col-lg-8 col-sm-10 col-12 m-auto">
        <h1 className="mb-4">Thank You!</h1>
        {responseData && (
          <div>
            <p
              className="col-md-9 col-12 text-center mx-auto"
              dangerouslySetInnerHTML={{ __html: responseData.message }}
            ></p>
          </div>
        )}
      </div>
    </WebContainer>
  );
};

export default ThankYouPage;
