import React from 'react';
import Container from '../Container/Index';
import Swiperslider from '../Swiperslider/Index';
import ImageTag from '../ImageTag/Index';
import './index.css';
import Title from '../Title/Index';

const Index = ({ Data, bgImage }) => {
  return (
    <Container containerClass={'float-end position-relative'} sectionID={`policy`}>
      <div className={`col-12 float-start p-100 blueLayer ${bgImage}`}>
        <div className='Number_Section position-relative col-lg-6 col-sm-8 col-11 m-auto'>
          <Title 
            firstHeading={Data.heading} 
            secondHeading={Data.title} 
            subTitle={Data.descp} 
            _class={'text-white'} 
            _secondHeading={'text-white'} 
            _spanClass={'text-white'} 
            Subheading={true} 
            sub_title={Data.sub_title} 
          />
        </div>
      </div>

      {Array.isArray(Data.clientLogo) && Data.clientLogo.length > 0 && (
        <div className='col-12 float-start clientLogo border-bottom pb-4'>
          <Swiperslider 
            spaceBetween={1} 
            slidesPerView={6}
            navigation={false} 
            pagination={false} 
            timeDelay={8000}
            tabsliderperview={4}
            smallsliderperview={3}
            mobsliderperview={2}
          >
            {Data.clientLogo.map((logo, index) => (
              <div 
                className='col-12 text-center flex-center pt-3' 
                key={index}
                data-aos="fade-in"
                data-aos-offset="200"
                data-aos-duration="500"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
              >
                <ImageTag 
                  ImagePath={logo.imagePath} 
                  ImageAlt={logo.imageAlt} 
                />
              </div>
            ))}
          </Swiperslider>
        </div>
      )}
    </Container>
  );
};

export default Index;
