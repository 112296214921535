import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrumb from "./components/BreadCrumb/Index";
import Container from "./components/Container/Index";
import WebContainer from "./components/WebContainer/Index";
import Title from "./components/Title/Index";
import axios from "axios";
import { API_BASE_URL } from "./Utils/useApi";

const ContentPage = () => {
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/policy_page.php?category=${slug}`
        );
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setPageData(response.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  console.log("doctorData", pageData);

  // Function to render HTML content
  const renderContent = (content) => {
    return content.map((item, index) => {
      if (item.type === "html") {
        return (
          <div key={index} dangerouslySetInnerHTML={{ __html: item.html }} />
        );
      }
      return null;
    });
  };

  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (!pageData) {
    return (
      <div className="mx-auto p-100 w-100 my-5 d-flex justify-content-center">
        No data found
      </div>
    );
  }

  if (error) {
    return (
      <div className="mx-auto p-100 w-100 d-flex justify-content-center">
        No data found
      </div>
    );
  }

  // Check if content exists and is non-empty
  const content = pageData.policy_page?.content;
  const isContentEmpty = !content || content.trim() === "";

  return (
    <>
      <BreadCrumb pageName={pageData.policy_page.heading} />
      <WebContainer>
        <Title
          firstHeading={"IEC"}
          secondHeading={pageData.policy_page.heading}
          headingOne={true}
        />
        <div className="col-12 float-start pb-5">
          <div className="pageContent">
            {isContentEmpty ? (
              <div className="text-center">
                <h1 className="fs-1 opacity-50">Content Not Available!</h1>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </div>
        </div>
      </WebContainer>
    </>
  );
};

export default ContentPage;
