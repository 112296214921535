import React, { useEffect, useState } from "react";
import "../Footer/Footer.css";
import Container from "../Container/Index";
import { Facebook } from "../../images/Vectors/Facebook";
import { Instagram } from "../../images/Vectors/Instagram";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { HashLink } from 'react-router-hash-link';

const Index = () => {
  const year = new Date().getFullYear();
  const [menuData, setMenuData] = useState([]); // State to hold menu data

  useEffect(() => {
    // Fetch the menu data from the API
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(
          "https://triverseadvertising.com/iec_website/admin-portal/api/menu.php"
        );
        setMenuData(response.data); // Assuming the response data is an array of menu objects
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, []);

  return (
    <>
      <footer className="col-12 float-start">
        <Container
          _parentClass="p-100 pb-0"
          containerClass={"float-end bg-primary topBorderband"}
        >
          <div
            className="col-lg-10 col-sm-11 col-12 m-auto p-100 pb-2"
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-duration="500"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="col-12 mb-sm-5 float-start">
              <div className="footerbox col-12 float-start">
                <div className="col-12 float-start d-flex justify-content-between flex-wrap text-white">
                  <div className="footercolumn">
                    <h3 className="fw-bold text-uppercase mb-sm-3  mb-1">Home</h3>
                  </div>
                  <div className="footercolumn">
                    <h3 className="fw-bold text-uppercase mb-sm-3  mb-1">About us</h3>
                    <ul>
                      <li>
                        <NavLink to="/about-iec-gensets">Overview</NavLink>
                      </li>
                      <li>
                        <HashLink smooth to="/about-iec-gensets#message">
                          MD’s Message
                        </HashLink>
                      </li>
                      <li>
                        <HashLink smooth to="/about-iec-gensets#ethos">
                          Corporate Ethos
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                  <div className="footercolumn">
                    <h3 className="fw-bold text-uppercase mb-sm-3  mb-1">Power Solutions</h3>
                    <ul>
                      {menuData.map((submenu, Index) => (
                        <li key={Index}>
                          <NavLink to={`power-solutions/${submenu.slug}`}>
                            {submenu.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="footercolumn">
                    <h3 className="fw-bold text-uppercase mb-sm-3  mb-1">Manufacturing Plant</h3>
                    <ul>
                      <li>
                        <NavLink to="manufacturing-plant">Overview</NavLink>
                      </li>
                      <li>
                        <HashLink smooth to="/manufacturing-plant#features">
                          Salient Features
                        </HashLink>
                      </li>
                      <li>
                        <HashLink smooth to="/manufacturing-plant#policy">
                          Quality Policy
                        </HashLink>
                      </li>
                      <li>
                        <HashLink smooth to="/manufacturing-plant#gallery">
                          Manufacturing Gallery
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                  <div className="footercolumn">
                    <h3 className="fw-bold text-uppercase mb-sm-3  mb-1">Quick Links</h3>
                    <ul>
                      <li>
                        <NavLink to="/customer-support">Customer Support</NavLink>
                      </li>
                      <li>
                        <NavLink to="/certifications">Certifications</NavLink>
                      </li>
                      <li>
                        <NavLink to="/clients">Clients</NavLink>
                      </li>
                      <li>
                        <NavLink to="/career">Career</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us">Contact us</NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="footercolumn d-none">
                    <h3 className="fw-bold text-uppercase mb-sm-3  mb-1">Follow Us</h3>
                    <div className="footerbox follow_us">
                      <ul className="d-grid">
                        <li className="fb">
                          <span>
                            <Facebook />
                          </span>
                        </li>
                        <li className="tel">
                          <span>
                            <Instagram />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 float-start d-flex justify-content-between pt-3 pb-3 mt-sm-5 copyrightcont text-white">
              <p>
                &copy; {year} All Rights Reserved
                {/* <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-conditions">Terms & Conditions</Link>
                <Link to="/legal-disclaimer">Legal Disclaimer</Link> */}
              </p>
              <p>
                <a
                  href="https://triverseadvertising.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  site: triverse
                </a>
              </p>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Index;
