import React, { useEffect, useState } from 'react'
import Banner from "./components/Banner/Index";
import { CircularProgress } from "@mui/material";
import Container from "./components/Container/Index";
import FillShape from './images/Vectors/FillShape';
import GallerySlider from "./components/Gallery/GallerySlider";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import Client from "./components/Client/Index";
import Featureslider from './components/Gallery/Featureslider';
import Title from './components/Title/Index'
import WebContainer from './components/WebContainer/Index'
import axios from 'axios';
import { API_BASE_URL } from "./Utils/useApi";

const Manufacturing = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
        const fatchData = async() =>{
          try{
            const response = await axios.get(`${API_BASE_URL}/manufacturing_plant.php`);
            setPageData(response.data);
          }
          catch(err){
            setError(err.message);
          }
          finally{
            setloading(false);
          }
        };
        fatchData();
  }, []);
  if(loading)
    return(
      <div className="preloader flex-center">
        <CircularProgress size={24} />
      </div>
    );
  if(error)
    return(
      <div>Error: An error occurred while fetching data</div>
    );
    if(!pageData) return null;
    const { bannerData, overview, qualityData, gallery, feature } =
    pageData;
  return (
    <main>
        {bannerData && <Banner Data={bannerData[0]} />}
        {overview && (
        <WebContainer _parentClass="Overview" containerClass={'position-relative '}>
          <div className="col-12 float-start position-relative">         
                <div className='overviewSign'><FillShape /></div>
                <Title firstHeading={overview.title} secondHeading={overview.subTitle} subTitle={overview.descp}/>
          </div>
        </WebContainer>  
      )}
        <Container>
               {feature &&  <Featureslider Data={feature} />}
        </Container>
        {qualityData && <Client Data={qualityData} bgImage={'quality_Bg_Image'}/>}
        {gallery && <GallerySlider Data={gallery} />}
        <BusinessEnquiry />
        </main>
  )
}

export default Manufacturing