import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import WebContainer from "../WebContainer/Index";
import Title from "../Title/Index";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../Utils/useApi";
import axios from "axios";
import { setFormFilled } from "../../Utils/formStatus";

const Enquiry = () => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    fullName: Yup.string()
      .required("Name is required")
      .min(3, "Name must be at least 3 characters")
      .max(20, "Name must not exceed 20 characters")
      .matches(/^[A-Z a-z]+$/, "Name must contain only alphabets"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    mobile: Yup.string()
      .required("Required")
      .test("is-valid-phone", "Invalid phone number format", (value) => {
        const phoneNumberPattern = /(?:\+?\d{1,3})?[-.\s]?(\d{10})/;
        return phoneNumberPattern.test(value);
      }),
  });

  const showNotification = (message, severity = "success") => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", severity: "success" });
  };

  const extractPhoneNumber = (input) => {
    const phoneNumberPattern = /(?:\+?\d{1,3})?[-.\s]?(\d{10})/;
    const match = input.match(phoneNumberPattern);
    return match ? match[1] : "";
  };

  return (
    <WebContainer _parentClass={"BusinessEnquiry p-100 pb-5"}>
      <Title secondHeading={"Contact Us"} _class={"mb-3"} />
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          mobile: "",
        }}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const extractedPhone = extractPhoneNumber(values.mobile);
          const formData = {
            ...values,
            mobile: extractedPhone,
          };
          console.log("Form Data", formData);

          axios
            .post(`${API_BASE_URL}contact_submit.php`, formData)
            .then((response) => {
              console.log("Success:", response);
              if (response.data.status == "success") {
                console.log("Success:", response.data);
                setFormFilled("true", response.data);
                showNotification("Form submitted successfully!", "success");
                resetForm();
                navigate("/enquiry-submitted");
              } else {
                showNotification(
                  "Form submission failed. Please try again.",
                  "error"
                );
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              if (error.response) {
                const status = error.response.status;
                const errorMessage =
                  error.response.data.message || "An error occurred";

                if (status === 400) {
                  showNotification(
                    "Bad request. Please check your input.",
                    "error"
                  );
                } else if (status === 401) {
                  showNotification(
                    "Unauthorized access. Please log in.",
                    "error"
                  );
                } else if (status === 500) {
                  showNotification(
                    "Server error. Please try again later.",
                    "error"
                  );
                } else {
                  showNotification(`Error: ${errorMessage}`, "error");
                }
              } else if (error.request) {
                showNotification(
                  "No response from the server. Please try again.",
                  "error"
                );
              } else {
                showNotification("Error: " + error.message, "error");
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting, validateForm }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validateForm().then((formErrors) => {
                const errorMessages = Object.values(formErrors);
                if (errorMessages.length > 0) {
                  showNotification(
                    "Please complete all required fields.",
                    "error"
                  );
                } else {
                  handleSubmit();
                }
              });
            }}
          >
            <Box
              className="rowFormControl d-flex gap-3"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <Field name="fullName">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="FULL NAME*"
                    margin="normal"
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="email">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="EMAIL ID*"
                    margin="normal"
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="mobile">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="MOBILE NO*"
                    margin="normal"
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Box>
            <Box
              textAlign="center"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <button
                type="submit"
                className="customButton"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
              </button>
            </Box>
          </Form>
        )}
      </Formik>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </WebContainer>
  );
};

export default Enquiry;
