import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import WebContainer from "../WebContainer/Index";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  FormHelperText,
} from "@mui/material";
import "./index.css";
import Title from "../Title/Index";
import { Navigate, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../Utils/useApi";
import axios from "axios";
import { setFormFilled } from "../../Utils/formStatus";

const BusinessEnquiry = () => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [segments, setSegments] = useState([]);
  const [products, setProducts] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [errorState, setErrorState] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchStates();
    fetchSegments();
    fetchProducts();
  }, []);

  const fetchStates = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/get_states.php`);
      const data = await response.json();
      console.log("states", data); // log to ensure you're getting the correct data
      setStates(data); // Corrected from setStates(data.states)
    } catch (error) {
      console.error("Error fetching states:", error);
      showNotification("Failed to fetch states", "error");
    }
  };

  const fetchCities = async (stateId, stateid) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}get_cities.php?sid=${stateid}`
      );
      const data = await response.json();
      console.log("cities", data);
      setCities(data);
    } catch (error) {
      console.error("Error fetching cities:", error);
      showNotification("Failed to fetch cities", "error");
    }
  };

  const fetchSegments = async () => {
    const data = [
      "Public Sector",
      "Telecom Sector",
      "Manufacturing Sector",
      "Educational Sector",
      "Hospitality Sector",
      "Infrastructure Sector",
      "Retail Sector",
    ]; // Replace with API call
    setSegments(data);
  };

  const fetchProducts = async () => {
    const data = [
      "Gas Gensets",
      "Diesel Gensets",
      "LT & HT Panels",      
      "Turnkey Projects",
      "Hybrid Power Solutions",      
      "Compact Substations",
    ];
    // Replace with API call
    setProducts(data);
  };
  const extractPhoneNumber = (input) => {
    const phoneNumberPattern = /(?:\+?\d{1,3})?[-.\s]?(\d{10})/;
    const match = input.match(phoneNumberPattern);
    return match ? match[1] : "";
  };

  const validationSchema = Yup.object({
    fullName: Yup.string()
      .required(" Name is required")
      .min(3, "Name must be at least 3 characters")
      .max(20, "Name must not exceed 20 characters")
      .matches(/^[A-Z a-z]+$/, "Name must contain only alphabets"),
    company: Yup.string().required("Company is required").max(100),
    mobile: Yup.string()
      .required("Required")
      .test("is-valid-phone", "Invalid phone number format", (value) => {
        const phoneNumberPattern = /(?:\+?\d{1,3})?[-.\s]?(\d{10})/;
        return phoneNumberPattern.test(value);
      }),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    // state: Yup.string()
    //   .required("State is required")
    //   .test("valid-state", "Please select a valid state", function (value) {
    //     // Check if the selected state is part of the loaded states
    //     return states.some((state) => state.sname === value);
    //   }),

    // Lazy validation for city based on selected state
    // city: Yup.string()
    //   .required("City is required")
    //   .test("valid-city", "Please select a valid city", function (value) {
    //     // Check if the selected city is part of the loaded cities
    //     return cities.some((city) => city.cityname === value);
    //   }),

    // segment: Yup.string()
    //   .required("Segment is required")
    //   .oneOf(segments, "Please select a valid segments"),
    // product: Yup.string()
    //   .required("Product is required")
    //   .oneOf(products, "Please select a valid products"),
  });

  const showNotification = (message, severity = "error") => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", severity: "success" });
  };

  return (
    <WebContainer _parentClass={"BusinessEnquiry"}>
      <Title secondHeading={"Request a Quote"} _class={"mb-3"} />
      <Formik
        initialValues={{
          fullName: "",
          company: "",
          mobile: "",
          email: "",
          state: "",
          city: "",
          segment: "",
          product: "",
          describeRequirement: "",
        }}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const extractedPhone = extractPhoneNumber(values.mobile);
          const formData = {
            ...values,
            mobile: extractedPhone,
          };
          console.log("Form Data", formData);
          axios
            .post(`${API_BASE_URL}request_a_quote_submit.php`, formData)
            .then((response) => {
              // Check for a successful response (you can adjust the condition based on your API response structure)
              console.log("Success:", response.data);
              if (response.data.status == "success") {
                // Assuming 'success' is a property in your response
                console.log("Success:", response.data);
                setFormFilled("true", response.data);
                showNotification("Form submitted successfully!", "success");
                resetForm();
                navigate("/enquiry-submitted"); // Redirect to thank you page
              } else {
                // Handle the case where the API response indicates failure despite a successful request
                showNotification(
                  "Form submission failed. Please try again.",
                  "error"
                );
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              if (error.response) {
                // Check if the error is from the server response
                const status = error.response.status;
                const errorMessage =
                  error.response.data.message || "An error occurred";

                if (status === 400) {
                  showNotification(
                    "Bad request. Please check your input.",
                    "error"
                  );
                } else if (status === 401) {
                  showNotification(
                    "Unauthorized access. Please log in.",
                    "error"
                  );
                } else if (status === 500) {
                  showNotification(
                    "Server error. Please try again later.",
                    "error"
                  );
                } else {
                  showNotification(`Error: ${errorMessage}`, "error");
                }
              } else if (error.request) {
                // No response received
                showNotification(
                  "No response from the server. Please try again.",
                  "error"
                );
              } else {
                // Request setup error
                showNotification("Error: " + error.message, "error");
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          validateForm,
        }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validateForm().then((formErrors) => {
                const errorMessages = Object.values(formErrors);
                if (errorMessages.length > 0) {
                  setErrorState(errorMessages);
                  handleSubmit();
                  if (errorMessages.length > 4) {
                    handleSubmit();
                    showNotification(
                      "Please review and complete all required fields.",
                      "error"
                    );
                  } else {
                    showNotification(errorMessages.join(", "), "error");
                  }
                } else {
                  handleSubmit();
                }
              });
            }}
          >
            <Box
              className="rowFormControl d-flex gap-3"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <Field name="fullName">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="FULL NAME*"
                    margin="normal"
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="company">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="COMPANY*"
                    margin="normal"
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="mobile">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="MOBILE NO*"
                    margin="normal"
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="email">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="EMAIL ID*"
                    margin="normal"
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Box>
            <Box
              className="rowFormControl d-flex gap-3"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="state-label">SELECT STATE</InputLabel>
                <Field name="state">
                  {({ field, meta }) => (
                    <Select
                      {...field}
                      labelId="state-label"
                      label="Select State"
                      error={meta.touched && Boolean(meta.error)}
                      onChange={(e) => {
                        console.log("Available states:", states);
                        console.log("Selected state ID:", e.target.value);

                        const selectedState = states.find(
                          (state) => state.sname === String(e.target.value) // Adjust for type
                        );

                        console.log("Selected State:", selectedState.stateid);
                        if (selectedState) {
                          fetchCities(e.target.value, selectedState.stateid);
                          setFieldValue("city", ""); // Reset city selection
                        }
                        field.onChange(e);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {states.map((state) => (
                        <MenuItem key={state.stateid} value={state.sname}>
                          {state.sname}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
                <FormHelperText error>
                  {touched.state && errors.state}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="city-label">SELECT CITY</InputLabel>
                <Field name="city">
                  {({ field, meta }) => (
                    <Select
                      {...field}
                      labelId="city-label"
                      label="Select City"
                      error={meta.touched && Boolean(meta.error)}
                      disabled={!values.state || cities.length === 0} // Disable if no state selected or no cities available
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {cities.map((city) => (
                        <MenuItem key={city.cityid} value={city.cityname}>
                          {city.cityname} {/* Display the city name */}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
                <FormHelperText error>
                  {touched.city && errors.city}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="segment-label">SELECT SEGMENT</InputLabel>
                <Field name="segment">
                  {({ field, meta }) => (
                    <Select
                      {...field}
                      labelId="segment-label"
                      label="Select Segment"
                      error={meta.touched && Boolean(meta.error)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {segments.map((segment) => (
                        <MenuItem key={segment} value={segment}>
                          {segment}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
                <FormHelperText error>
                  {touched.segment && errors.segment}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="product-label">SELECT PRODUCT</InputLabel>
                <Field name="product">
                  {({ field, meta }) => (
                    <Select
                      {...field}
                      labelId="product-label"
                      label="Select Product"
                      error={meta.touched && Boolean(meta.error)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {products.map((product) => (
                        <MenuItem key={product} value={product}>
                          {product}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
                <FormHelperText error>
                  {touched.product && errors.product}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box
              className="rowFormControl d-flex gap-3"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <Field name="describeRequirement">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Describe Your Requirement"
                    margin="normal"
                    variant="outlined"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    multiline
                    rows={2} // Set the number of rows to 2
                  />
                )}
              </Field>
            </Box>
            <Box
              textAlign="center"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
              <button
                type="submit"
                className="customButton"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
              </button>
            </Box>
          </Form>
        )}
      </Formik>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </WebContainer>
  );
};

export default BusinessEnquiry;
