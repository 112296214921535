import React, { useEffect, useState } from 'react'
import Banner from "./components/Banner/Index";
import Container from "./components/Container/Index";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import Title from './components/Title/Index'
import ImageTag from './components/ImageTag/Index'
import { API_BASE_URL } from "./Utils/useApi";
import axios from 'axios';
import { CircularProgress } from "@mui/material";
import Download from './images/Vectors/Download';

const Certifications = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
useEffect(() => {
      const fatchData = async () =>{
        try{
          const response = await axios.get(`${API_BASE_URL}/certifications.php`);
          setPageData(response.data);
        }
        catch (err){
          setError(err.message);
        }
        finally{
          setLoading(false);
        }
      };
      fatchData();
}, []);
if (loading) return  <div className="preloader flex-center">
<CircularProgress size={24} />
</div>;
if (error) return <div>Error: Unable to load certification details. Please try again later.</div>;
if (!pageData) return null;

const { homeBanners, certificationsData } = pageData;
return (
  <main>
    {homeBanners && homeBanners.length > 0 && <Banner Data={homeBanners[0]} />}
    <Container containerClass="position-relative float-end">
      <div className="col-12 float-start FillshapeCenter">
        <div className="col-lg-6 col-12 m-auto">
          <Title firstHeading={certificationsData?.title} secondHeading={certificationsData?.heading} subTitle={certificationsData?.descp} />
        </div>
      </div>
      <div className="col-lg-11 col-sm-12 col-12 m-auto clientLogo">
        <div className="row flex-center">
          {certificationsData?.certificationsLogo?.map((logo, index) => (
          
               <div
              className="col-lg-2 col-sm-6 col-12 text-center certificateStn border m-2"
              key={index}
              data-aos="fade-in"
              data-aos-offset="200"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine"
            >
                <a href={logo.document} target='_blank'>
              <div className="col-12 float-start certificationsImg pt-1 pb-1 border-0">
                <ImageTag ImagePath={logo.imagePath} ImageAlt={logo.imageAlt} />
              </div>
              {/* {logo.content && <p>{logo.content}</p>}     */}
              <p className='iconColor primary'><Download /></p>
            </a>
            </div>
          ))}
        </div>
      </div>
    </Container>
    <BusinessEnquiry />
  </main>
  )
}

export default Certifications