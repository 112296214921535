import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../BreadCrumb/BreadCrumb.css';
import Container from '../Container/Index';

const BreadCrumbs = ({ _parentClass, customBreadCrum, secondName, pageLink, firstName }) => {
  const location = useLocation();
  const pathParts = location.pathname.split('/').filter(Boolean);
  const pageName = pathParts[0] || 'Home';  
  const pageChildName = pathParts[1] || ''; 
  const pageUrl = pageChildName ? '/power-solutions' : null;
  const formattedPageName = pageName.replace(/-/g, ' ');
  const formattedPageChildName = pageChildName ? pageChildName.replace(/-/g, ' ') : null;

  return (
    <Container containerClass={'float-end'}>
      <div className={`pagebreadcumb mt-2 ${_parentClass}`}>
        <ul className='d-flex'>
          <li><Link to='/'>Home</Link></li>
          <li>{pageUrl ? <Link to={pageUrl}>{formattedPageName}</Link> : <span>{formattedPageName}</span>}</li>
          {formattedPageChildName && <li>{formattedPageChildName}</li>}
        </ul>
        {customBreadCrum &&   <ul className='d-flex'>
          <li><Link to='/'>Home</Link></li>
          <li>{pageLink ? <Link to={pageLink}>{firstName}</Link> : <span>{firstName}</span>}</li>
          {secondName && <li>{secondName}</li>}
        </ul>}
      </div>
    </Container>
  );
};

export default BreadCrumbs;
