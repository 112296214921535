import React from "react";
import ImageTag from "../ImageTag/Index";
import Button from "../Button/Fill/Index";

const ProductCard = ({ Data }) => {
  // const productTitle = Data.title ? Data.title.replace(/\s+/g, '-').toLowerCase() : '';
  // const productLink = Data.slug ? `${productTitle}` : null;

  return (
    <div className="cardStnComponent card h-auto m-0">
      <div className="col-12 float-start flex-center flex-wrap gap-2">
        <div className="productTitle text-center">
          <h3 className="fw-bold text-black">{Data.title}</h3>
        </div>
        <div className="productImg">
          <ImageTag ImagePath={Data.imagePath} ImageAlt={Data.title} />
        </div>
      
        <div className="productDescp col-12 float-start">
          {Data.descriptions && 
          <ul className="flex-center flex-wrap gap-3 justify-content-start">
            {Data.descriptions.map((desc, index) => (
              <li
                key={index}
                className="d-grid col-12 float-start flex-wrap gap-1"
              >
                <span>{desc.title}</span>
                <strong>{desc.value}</strong>
              </li>
            ))}
          </ul>
          }
        </div>
       
        <div className="productButton col-12 float-start">
          <Button
            buttonText1={"View Detail"}
            buttonText2={"View Detail"}
            _class={"w-100"}
            pageLink={Data.slug}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
