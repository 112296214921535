import React from 'react';
import Title from '../Title/Index';
import WebContainer from '../WebContainer/Index';
import ImageTag from '../ImageTag/Index';
import Outline from '../Button/Outline/Index';

const Leadercard = ({ Data }) => {
  return (
    <>
    
      <WebContainer _class={'iecLeaders'} sectionID={'leadership'}>
        <Title firstHeading="Leadership Team" secondHeading="Meet the Captains of IEC" />
        <div className="col-12 float-start">
          <div className="row">
            {Data.map((item, index) => (
              <div className="col-lg-6 col-sm-12 col-12 pb-5" key={index}   data-aos="fade-up"
              data-aos-offset="100"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-easing="ease-in-sine">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <ImageTag ImagePath={item.imagePath} ImageAlt={item.Name} />
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="leaderInfo">
                      <h3>{item.Name}</h3>
                      <span>{item.Designation}</span>
                      {item.Description === "" ? null : (
                        <Outline buttonText="Read Bio" _class="mt-5" pageLink={`/leadership/${item.slug}`}/>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </WebContainer>
    </>
  );
};

export default Leadercard;
