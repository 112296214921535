import React from 'react'

export const Facebook = (props) => {
  return (
    <>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 32 32"
    {...props}
  >
    <g fill="#000">
      <path d="M16-.035C7.159-.035-.035 7.159-.035 16S7.159 32.035 16 32.035 32.035 24.841 32.035 16 24.841-.035 16-.035zm0 31C7.748 30.965 1.035 24.252 1.035 16S7.748 1.035 16 1.035 30.965 7.748 30.965 16 24.252 30.965 16 30.965z" />
      <path d="M19.5 7h-2.668C13.652 7 13 8.737 13 11.345L13.002 13H11.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5H13v7.5a.5.5 0 0 0 .5.5h3.058a.5.5 0 0 0 .5-.5l.002-7.5h2.44a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-2.441l.003-1.396c0-.673 0-.673.629-.674l1.789.07a.502.502 0 0 0 .52-.5v-3a.5.5 0 0 0-.5-.5zM19 9.98l-1.289-.05c-1.521 0-1.648.827-1.648 1.672l-.004 1.896a.5.5 0 0 0 .499.502H19v2h-2.44a.5.5 0 0 0-.5.5l-.002 7.5H14v-7.5a.5.5 0 0 0-.5-.5H12v-2h1.503a.5.5 0 0 0 .5-.501L14 11.344C14 9.079 14.424 8 16.832 8H19v1.98z" />
    </g>
  </svg>
    </>
  )
}
