import React from 'react'
import Data from './Json/Contact.json';
import Banner from "./components/Banner/Index";
import Enquiry from './components/Forms/Enquiry' 
import Container from './components/Container/Index';

const { bannerData } = Data;
const Contact = () => {
  return (
  <>
     {bannerData && <Banner Data={bannerData} />}
    <Enquiry /> 
    <Container containerClass={'pt-5 mt-5 border-top float-end'} _parentClass={'ContactDetails'}>
      <div className='col-lg-10 col-11 m-auto mt-lg-5'>
      <div className='row'>
      <div className='col-lg-3 col-sm-6 col-12 '>
        <div className='contactDetail'>
        <span className='fs-2 fw-100'>T</span>
          <p><a href="tel:911724374444">+91-172-4374444</a>, <a href="tel:9193160 45838">+91-93160 45838</a></p>
        </div> 
      </div>
      <div className='col-lg-3 col-sm-6 col-12 '>
        <div className='contactDetail'>
          <span className='fs-2 fw-100'>E</span>
          <p><a href="mailto:iec.chandigarh@iecgensets.com">iec.chandigarh@iecgensets.com</a></p>
        </div> 
      </div>
     <div className='col-lg-4 col-sm-6 col-12 '>
        <div className='contactDetail'>
        <span className='fs-2 fw-100'>A</span>
          <p><strong className='text-uppercase'>Corporate Address</strong><br/>Industrial Equipements Company Ltd.<br /> S.C.O. 36, Sector 26, Madhya Marg<br /> Chandigarh - 160019 </p>
        </div> 
        <div className='contactDetail'>
          <p><strong className='text-uppercase'>Factory Address</strong><br/>Plot No. 1-6A, IID Centre<br/>SICOP, Govindsar<br/>Kathua - 184102 (J&K)</p>
        </div> 
      </div>
      <div className='col-lg-2 col-sm-6 col-12'>
        <div className='contactDetail'>
        <span className='fs-2 fw-100'>M</span>
          <p><a href="https://maps.app.goo.gl/WmfiMmrtpVQKASLT7" target="_blank">View Location Map</a></p>
        </div> 
      </div>
      </div>
      </div>
    </Container>
  </>
  )
}

export default Contact