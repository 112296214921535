import './index.css';
import { useInView, motion } from 'framer-motion';
import { useRef } from 'react';
import { slideUp } from './animation';
import Container from '../Container/Index';
import FillShape from '../../images/Vectors/FillShape'
import Title from '../../components/Title/Index'
import { Link } from 'react-router-dom';

const Index = ({Data}) => {
    const description = useRef(null);
    const isInView = useInView(description);
    
    return (
        <>
            <Container _parentClass='Overview' containerClass={'position-relative'}>
                        <div ref={description} className="description col-lg-11 col-sm-10 col-11 m-auto">
                        <div className="row body justify-content-between">
                                <div className='col-lg-6  col-12 px-lg-5'>
                                    <Title firstHeading={Data.heading} secondHeading={Data.title} _class={'px-sm-4 px-3 text-start greenBorder position-relative'} headingOne={true}/>
                                </div>
                                <div className='col-lg-6  col-12 p-lg-0 position-relative'>
                                <div className='overviewSign'><FillShape /></div>
                                <p className='m-0 position-relative'>
                                    {Data.overview[0].split(" ").map((word, index) => (
                                        <span key={index} className="mask">
                                            <motion.span 
                                                variants={slideUp} 
                                                custom={index} 
                                                animate={isInView ? "open" : "closed"} 
                                                key={index}
                                            >
                                                {word}
                                            </motion.span>
                                        </span>
                                    ))}
                                </p>
                                
                                <div className='col-12 float-start' data-aos="fade-up" data-aos-offset="100" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine">
                                   <Link to='/about-iec-gensets'> <span className='customButton'>Know More</span></Link>
                                </div>
                                </div>
                            </div>
                        </div>
            </Container>
        </>
    );
}

export default Index;
